import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import { Link } from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const Mobilita = () => (
  <Layout isPagina={true}>
    <SEO
      title="Mobilità Sostenibile nel Lazio"
      description="Soluzioni per la mobilità elettrica e sostenibile in tutta la regione Lazio. Operiamo a Roma, Anzio, Nettuno, Latina, Pomezia e Aprilia"
    />
    <BanneronePage title="Mobilità Sostenibile" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Mobilità Sostenibile Lazio" />
      <div className="text-center">
        <img
          className="text-center rounded mt-1 mx-auto"
          src="../mobi/mobilita-sostenibile.jpg"
          alt="Mobilità Sostenibile"
        />
      </div>

      <CardPagina variante="bordoTop">
        <h2>NSE srl - eMobility</h2>
        <p>
          Il futuro è nelle nostre mani, sta a noi renderlo più verde, più
          respirabile e vivibile. I mezzi elettrici non sono una speranza per il
          futuro ma una realtà già nel presente: biciclette servoassistite,
          velocipedi elettrici a energia solare, automobili elettriche, moto e
          scooter ti libereranno dalla schiavitù del caro benzina e
          dall'inquinamento dei carburanti fossili.
        </p>
        <p>
          <u>Le molte risorse del tuo impianto fotovoltaico</u>
          <br />
          Grazie all'impianto fotovoltaico potrai accumulare la tua energia e
          farne quello che vorrai: alimentare le utenze domestiche come phon,
          lavatrice e forno ma anche ricaricare i tuoi mezzi elettrici
          risparmiando benzina e polveri sottili, anche mediante le{" "}
          <u>pensiline fotovoltaiche</u> e le <u>stazioni di ricarica</u>{" "}
          progettate e realizzate da NSE srl nella regione Lazio.
          <br />
          NonsoloEnergia srl è partner commerciale dei principali produttori
          europei di biciclette e scooter elettrici coma ITALWIN (Italia) ed
          EMCO (Germania) e partner del principale produttore mondiale di
          stazioni di ricarica, MENNEKES (Germania), SILLA (Italia), TESLA, etc.
        </p>
      </CardPagina>
      <Row>
        <Col sm={4}>
          <img
            className="rounded mt-1 img-fluid"
            src="../mobi/carport-fotovoltaico-lazio.jpg"
            alt="Carport Fotovoltaico Lazio"
          />
        </Col>
        <Col sm={4}>
          <img
            className="rounded mt-1 img-fluid"
            src="../mobi/magnanti-carport.jpg"
            alt="Magnanti Carport Anzio Nettuno Roma"
          />
        </Col>
        <Col sm={4}>
          <img
            className="rounded mt-1 img-fluid"
            src="../mobi/MENNEKES.jpg"
            alt="MENNEKES"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <img
            className="rounded mt-1 img-fluid"
            src="../mobi/parcheggio-fotovoltaico.jpg"
            alt="Parcheggio Fotovoltaico Lazio"
          />
        </Col>
        <Col sm={4}>
          <img
            className="rounded mt-1 img-fluid"
            src="../mobi/pensilina-fotovoltaica-azienda.jpg"
            alt="Pensilina Fotovoltaica Azienda"
          />
        </Col>
        <Col sm={4}>
          <img
            className="rounded mt-1 img-fluid"
            src="../mobi/pensilina-fotovoltaica-in-legno.jpg"
            alt="Pensilina Fotovoltaica in legno"
          />
        </Col>
      </Row>
      <CardPagina variante="bordoTop">
        <h2>NSE srl - eMobility Evolution</h2>
        <p>
          NSE srl, grazie alla profonda esperienza ed all'estro dei propri
          ingegneri elettrici ed energetici, in collaborazione con la Mobility
          Evolution dei F.lli Quattrini in Anzio , progetta e realizza
          velocipedi e risciò elettrici e fotovoltaici conformi al codice della
          strada; risciò polivalenti per il trasporto di persone e disabili,
          grazie alla particolare struttura dei veicoli a pianale ribassato e ad
          ancoraggi ad hoc per le carrozzelle.
        </p>
        <Row>
          <Col xs={12}>
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../mobi/ciklope.jpg"
              alt="Ciklope F.lli Quattrini Anzio"
            />
          </Col>
        </Row>
        <p>
          I risciò fotovoltaici prodotti sono di diverse dimensioni e potenze
          relative a differenti capacità di produzione fotovoltaica e di storage
          (accumulo) e quindi di produzione di energia elettrica da fonte
          solare. Le particolarità di tali risciò fotovoltaici realizzati dal
          2017, ad impatto ambientale zero e totalmente ecologici e ad energia
          rinnovabile, sono rappresentate da:
        </p>
        <ul>
          <li>
            moduli fotovoltaici ad elevatissima efficienza e resistenza in
            silicio cristallino ed amorfo sia di tipo rigido per i modelli di
            risciò più grandi{" "}
            <a
              href="http://www.mobilityevolution.it/ciklope-riscio-fotovoltaico/"
              rel="nofollow"
              target="_blank"
            >
              (modello CIKLOPE Fotovoltaico)
            </a>
            , sia di tipo flessibile per i modelli più piccoli;
          </li>
          <li>
            regolatori di carica a ridottissime perdite in grado di gestire
            contemporaneamente l'alimentazione del motore e la carica delle
            batterie;
          </li>
          <li>
            funzionamento solo da fotovoltaico, solo da batterie ed in modalità
            ibrida: fotovoltaico + batterie per salite ripide o carichi elevati
            sul risciò;
          </li>
          <li>erogazione energia prodotta da 12V a 48 V;</li>
          <li>potenza erogata ai motori da 200W a 1200W;</li>
          <li>prese USB, 12 V e 220Vca;</li>
          <li>
            gestione e monitoraggio del sistema mediante software sviluppato ad
            hoc, sia da display sul veicolo sia via app da tablet o smartphone;
          </li>
          <li>
            possibilità di realizzazioni personalizzate a seconda della
            particolare esigenza del cliente.
          </li>
        </ul>
        <Row className="mx-auto">
          <Col xs={12}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                width="420"
                height="315"
                src="https://youtu.be/AkgGKilFi9I"
              />
            </div>
          </Col>
        </Row>
      </CardPagina>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              Mobilità sostenibile: progettazione, realizzazione e fornitura di
              risciò fotovoltaici, velocipedi, bici elettriche di alta qualità e
              scooter europei
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Contattaci per info e preventivi"
              link="richiesta-informazioni"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default Mobilita
